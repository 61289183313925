<template>
    <SubNavCardVue :buttons="buttons" />
</template>

<script>
import SubNavCardVue from '../../components/cards/SubNavCard.vue'

export default {
  components: { SubNavCardVue },
  data() {
    return { 
      buttons: [
        {
          label: 'Commandes clients',
          icon: 'bi bi-clipboard-check-fill',
          route: 'orders-index'
        }, 
        {
          label: 'Commandes fournisseurs',
          icon: 'bi bi-clipboard-check-fill',
          route: 'orders-suppliers-index'
        }
      ]
    }
  }
}
</script>
